<template>
  <div class="row col-xl-12 mx-0 px-0">
    <!--start::TOPFORM-->
    <!-- here be dragons - hide for now :additionalActions="computedActions" -->
    <Topform
      ref="page-section-topform"
      :managementType="managementType"
      :status="null"
      pageType="page_section"
      :name="pageSectionInfo.title"
      :errors="pageSectionErrors"
      :submissionStates="submissionStates"
      parentPage="/project/pages/summary"
      :tabs="computedTabs"
      :validation="computedvalidation"
      :loadingComplete="loadingComplete"
      @clear_errors="clearErrors()"
      @panel_asset_reservation_created="form.panel = $event"
      @panel_asset_reservation_deleted="form.panel = null"
      @panel_remove_project_asset="banishPanel"
      @media_asset_reservation_created="form.media.push($event)"
      @media_asset_reservation_deleted="form.media = form.media.filter(function(stim) { return stim !== $event })"
      @media_remove_project_asset="form.media_remove.push($event)"
      @submit="submit(false)"
      @save_and_add_another="submit(true)"
      @clear_existing_data="clearData()"
    >
    </Topform>
    <!--end::TOPFORM-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import PageLoader from "@/core/classes/PageLoader.js";
import Toast from "@/core/classes/Toast.js";

//Vuex Imports
import { POPULATE_PAGE_INFO } from "@/core/services/store/page/page.module";
import {
  POPULATE_PAGE_SECTION_INFO,
  CREATE_PAGE_SECTION,
  UPDATE_PAGE_SECTION,
  CLEAR_PAGE_SECTION_ERRORS,
  CLEAR_PAGE_SECTION_INFO
} from "@/core/services/store/page/page_section.module";

//Topform Imports
import TopformConfig from "@/core/config/topform/ManagePageSection.js";

export default {
  mixins: [validationMixin],

  name: "ManagePageSection",

  data() {
    return {
      pageLoader: new PageLoader(1),
      toast: new Toast(),
      submissionStates: {
        submitting: false
      },
      error: {
        active: false,
        message: ""
      },
      form: {
        published: 1,
        title: "",
        panel: null,
        description: "",
        media: [],
        media_remove: [],
        panel_remove: [],
        layout: 1
      },
      form_reset: {},
      sectionLayoutOptions: [
        { id: 1, name:  `${this.$t("PAGES.MANAGE_SECTION.LAYOUT_1")}` },
        { id: 2, name:  `${this.$t("PAGES.MANAGE_SECTION.LAYOUT_2")}` },
        { id: 3, name:  `${this.$t("PAGES.MANAGE_SECTION.LAYOUT_3")}` },
      ],
    }
  },

  validations: {
    form: {
      published: {
        required
      },
      title: {
        required,
        maxLength: maxLength(255)
      },
      panel: {
        required: requiredIf(function() {
          return this.pageInfo.system_page_type_id == 2;
        })
      },
      description: {
        required
      },
      layout: {
        required
      }
    }
  },

  mounted() {
    this.form_reset = JSON.parse(JSON.stringify(this.form));

    if (this.$route.params.section != undefined) {
      this.pageLoader.addRequiredComponent();
      this.fetchExistingPageSection();
    };

    this.fetchExistingPage();
  },

  methods: {
    banishPanel: function(val) {
      this.form.panel = null;
      this.form.panel_remove.push(val);
    },
    fetchExistingPage: function() {
      let payload = {
        projectId: this.projectInfo.id,
        id: this.$route.params.page,
        params: {}
      };
      this.$store.dispatch(POPULATE_PAGE_INFO, payload)
      .then(() => {
        this.pageLoader.setCompleteComponent();
      })
    },
    fetchExistingPageSection: function() {
      let payload = {
        pageId: this.$route.params.page,
        id: this.$route.params.section,
        params: {
          projectAssets: 'with',
          appendSignedUrls: 1
        }
      };
      this.$store.dispatch(POPULATE_PAGE_SECTION_INFO, payload)
      .then(() => {
        this.form.published = this.pageSectionInfo.published;
        this.form.title = this.pageSectionInfo.title;
        this.form.description = this.pageSectionInfo.description;
        this.form.layout = this.pageSectionInfo.layout;

        if (this.pageInfo.system_page_type_id == 2) {
          if (this.mediaBreakdown.panels.length > 0) {
            this.form.panel = this.mediaBreakdown.panels[0].id;
          }
        }

        this.pageLoader.setCompleteComponent();
      })
    },
    submit: function(addSection) {
      this.clearErrors();

      this.submissionStates.submitting = true;

      if (!this.newSection) {
        this.submitUpdate(addSection);
      } else {
        this.submitPost(addSection);
      }
    },
    submitUpdate: function(addSection) {
      let form = this.form;

      if (this.pageInfo.system_page_type_id != 2) {
        delete form.panel;
      }

      let payload = {
        pageId: this.$route.params.page,
        id: this.$route.params.section,
        data: form
      }

      this.$store
        .dispatch(UPDATE_PAGE_SECTION, payload)
        .then(() => {
          this.toast.notify(this, this.form.title + ' ' + `${this.$t("TOASTS.EDIT_PAGE.MESSAGE")}`, `${this.$t("TOASTS.EDIT_PAGE.TITLE")}`, 'success');
          if (addSection) {
            this.resetForm();
          } else {
            this.resetForm();
            this.$router.push({ name: 'pages_summary' });
          }
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    submitPost: function(addSection) {
      let form = this.form;

      if (this.pageInfo.system_page_type_id != 2) {
        delete form.panel;
      }

      let payload = {
        pageId: this.$route.params.page,
        data: form
      }

      this.$store
        .dispatch(CREATE_PAGE_SECTION, payload)
        .then(() => {
          this.toast.notify(this, this.form.title + ' ' + `${this.$t("TOASTS.ADD_PAGE.MESSAGE")}`, `${this.$t("TOASTS.ADD_PAGE.TITLE")}`, 'success');
          if (addSection) {
            // here be dragons - there are 2 issues when you do this. First being that the error for the description is not reset.
            // second issue is that the dropzone holds the preview from the previous submit.
            // we need to re-mount the dropzone really
            this.resetForm();
          } else {
            this.resetForm();
            this.$router.push({ name: 'pages_summary' });
          }
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    resetForm: function() {
      this.form = JSON.parse(JSON.stringify(this.form_reset));
      this.scrollTop();
      // here be dragons - this validation reset doesn't reset the error on description
      // what we want is for description to be invalid (because it is since it gets emptied) but not for the error to be active...
      // this is exactly what happens for the title field.
      this.$v.$reset();
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_PAGE_SECTION_ERRORS);
    },
    clearData: function() {
      this.$store.dispatch(CLEAR_PAGE_SECTION_INFO)
    },
    scrollTop: function() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "pageInfo",
      "pageSectionInfo",
      "pageSectionErrors"
    ]),

    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },

    calculatedCardTitle: function() {
      if (this.$route.params.section != undefined) {
        if (this.loadingComplete) {
          return `${this.$t("PAGES.MANAGE_SECTION.EDIT")}` + ' - ' + this.pageSectionInfo.title;
        } else {
          return `${this.$t("PAGES.MANAGE_SECTION.EDIT")}`;
        }
      } else {
        return `${this.$t("PAGES.MANAGE_SECTION.ADD")}`;
      }
    },

    managementType: function() {
      if (this.$route.params.section != undefined) {
        return "update";
      } else {
        return "post"
      }
    },

    assetReservationPanelData: function() {
      let data = {
        scope: 'page_section',
        data: {
          directory: 'panel',
          projectId: this.projectInfo.id
        }
      };

      if (this.newSection) {
        data.data.new = 1;
      } else {
        data.data.pageSectionId = this.pageSectionInfo.id
      }

      return data;
    },

    assetReservationMediaData: function() {
      let data = {
        scope: 'page_section',
        data: {
          directory: 'media',
          projectId: this.projectInfo.id
        }
      }

      if (this.newSection) {
        data.data.new = 1;
      } else {
        data.data.pageSectionId = this.pageSectionInfo.id
      }

      return data;
    },

    newSection: function() {
      if (this.$route.params.section != undefined) {
        return false;
      } else {
        return true;
      }
    },

    mediaBreakdown: function() {
      let breakdown = {
        panels: [],
        media: []
      };

      if (!this.newSection && this.pageSectionInfo.project_assets != null) {
        this.pageSectionInfo.project_assets.forEach((asset) => {
          if (asset.pivot.is_panel_picture) {
            if (!this.form.panel_remove.includes(asset.id)) {
              breakdown.panels.push(asset);
            }
          } else {
            breakdown.media.push(asset);
          }
        });
      }

      return breakdown;
    },

    // here be dragons - hide this for now

    // computedActions: function() {
    //   return [
    //     {
    //       name: `${this.$t("GENERAL.BUTTONS.SAVE_AND_ANOTHER_SECTION")}`,
    //       emit: 'save_and_add_another'
    //     }
    //   ]
    // },

    computedvalidation: function() {
      return this.$v.form;
    },

    computedTabs: function() {
      return TopformConfig.topform(this);
    }
  }
};
</script>
