class ManagePageSection {

    //Suggests a key based on either a client or project name
    static topform(component) {
        return [
          {
            id: 1,
            sections: [
              { 
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericCheckbox",
                        properties: {
                          class: "col-xl-12 px-5 mb-5",
                          id: 'input-published',
                          label: `${component.$t("FORMS.INPUTS.PUBLISH")}`,
                          model: component.$v.form.published,
                          submissionStates: component.submissionStates
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-12 px-5 mb-10",
                          id: 'input-page-section-title',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.TITLE")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.TITLE")}`,
                          model: component.$v.form.title,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.TITLE")}`,
                        }
                      }
                    ]
                  },
                  {
                    show: component.pageInfo.system_page_type_id == 2,
                    components: [
                      {
                        name: "IntegratedDropzone",
                        on: {
                          asset_reservation_created: (value) => { component.$refs["page-section-topform"].$emit('panel_asset_reservation_created', value) },
                          asset_reservation_deleted: (value) => { component.$refs["page-section-topform"].$emit('panel_asset_reservation_deleted', value) },
                          remove_project_asset: (value) => { component.$refs["page-section-topform"].$emit('panel_remove_project_asset', value) },
                        },
                        properties: {
                          class: "col-xl-12 px-5 mb-0",
                          assetReservation: component.assetReservationPanelData,
                          idx: 1,
                          projectAssets: component.mediaBreakdown.panels,
                          wholeWidth: true,
                          removable: true,
                          label: `${component.$t("FORMS.INPUTS.PANEL")}`,
                          model: component.$v.form.panel,
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputGenericRichText",
                        properties: {
                          class: "col-xl-12 px-5 mb-5",
                          id: 'input-page-section-description',
                          label: `${component.$t("FORMS.INPUTS.DESCRIPTION")}`,
                          model: component.$v.form.description,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.DESCRIPTION")}`,
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        name: "IntegratedDropzone",
                        on: {
                          asset_reservation_created: (value) => { component.$refs["page-section-topform"].$emit('media_asset_reservation_created', value) },
                          asset_reservation_deleted: (value) => { component.$refs["page-section-topform"].$emit('media_asset_reservation_deleted', value) },
                          remove_project_asset: (value) => { component.$refs["page-section-topform"].$emit('media_remove_project_asset', value) },
                        },
                        properties: {
                          class: "col-xl-12 px-5 mb-0",
                          assetReservation: component.assetReservationMediaData,
                          idx: 2,
                          projectAssets: component.mediaBreakdown.media,
                          wholeWidth: true,
                          removable: true,
                          label: `${component.$t("FORMS.INPUTS.MEDIA")}`
                        }
                      }
                    ]
                  },
                  {
                    show: false,
                    components: [
                      {
                        name: "InputPageSectionLayoutRadio",
                        properties: {
                          class: "px-5 mb-5",
                          id: 'input-page-section-layout',
                          label: `${component.$t("FORMS.INPUTS.SECTION_LAYOUT")}` + ':',
                          model: component.$v.form.layout,
                          options: component.sectionLayoutOptions,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.SECTION_LAYOUT")}`,
                        }
                      }
                    ]
                  },
                ]
              }
            ]
          }
        ]
    }
  
  }
  
export default ManagePageSection;